<template>
  <v-app>
    <TextBox
      :cefrLevel="payload.cefr_level"
      v-model="title"
      label="Title"
      placeholder="Title"
    />
    <label class="item-label mt-6">Speaking Text</label>
    <Textarea
      class="textarea"
      :rows="4"
      :cefrLevel="payload.cefr_level"
      v-model="speaking_text"
    />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import TextBox from "../../../components/item/TextBox";
import Textarea from "../../../components/item/Textarea";
import { v4 as uuidv4 } from "uuid";
export default {
  data: () => ({
    title: "",
    speaking_text: "",
    static_text: "Read out the phrase",
  }),
  computed: {
    ...mapState('item', ['payload', 'isUpdate']),
  },
  methods: {
    onSubmit() {
      const data = {
        template_type: "READ_ALOUD",
        static_text: this.static_text,
        title: this.title,
        speaking_text: this.speaking_text,
      }

      /**
       * The questions[] array here is hard-coded because
       *  a) it is designed to be static;
       *  b) the API requires it.
       *
       * It's only set on create, because it's not designed to be editable.
       */
      if (!this.isUpdate) {
        data.questions = [{
          question: this.static_text,
          question_id: uuidv4(),
          solution: [],
          metadatas: {"instructions": ["Hold and read the sentence"]}
        }]
      }
      this.$store.commit('item/setPayload', data)
    }
  },
  beforeMount() {
    if (this.isUpdate) {
      this.title = this.payload.title || "";
      this.speaking_text = this.payload.speaking_text || "";
    }
  },
  components: {
    TextBox,
    Textarea
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  border: unset;
  border-bottom: 3pt solid rgb(222, 222, 222);
}
.paragraphs {
  max-width: 500pt;
  box-sizing: border-box;
  .paragraph {
    display: flex;
    margin-bottom: 15pt;
    .number {
      position: relative;
      background: #393b71;
      display: block;
      height: 21pt;
      width: 21pt;
      border-radius: 21pt;
      text-align: center;
      color: white;
      margin-top: 13pt;
      margin-right: 10pt;
    }
    .textarea {
      width: 100%;
    }
  }
}
label {
  color: #264d98;
  font-size: 1em;
  margin-bottom: 10pt;
}
::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
